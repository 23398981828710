import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const UseGroupList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowUseGroup = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateUseGroup = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

const EditUseGroup = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};


const useGroupResource = () => <Resource
  name="useGroup"
  options={{ label: 'Use group', menuGroup: 'Bcome'}}
  list={UseGroupList}
  create={CreateUseGroup}
  edit={EditUseGroup}
  show={ShowUseGroup} />;

export default useGroupResource;
