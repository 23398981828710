import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    DateField,
    Resource,
    Show,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    Filter,
    AutocompleteInput,
    Datagrid } from "react-admin";


const Filters = (props) => (
    <Filter {...props}>

        <ReferenceInput alwaysOn label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput alwaysOn label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const ProcessRawMaterialCategoryList = (props) => {
    return (
        <List {...props} filters={Filters(props)}>
            <div style={{ marginTop: '15px' }}>
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <ReferenceField label="Process" source="processId" reference="process">
                        <TextField source="name" />
                    </ReferenceField>

                    <ReferenceField llabel="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory">
                        <TextField source="name" />
                    </ReferenceField>

                    <DateField source="createdAt" label="Creation date" />
                    <DateField source="updatedAt" label="Update date" />
                    <EditButton />
                </Datagrid>
            </div>
        </List>
    );
};

const ShowProcessRawMaterialCategory = (props) => {
    return (
        <Show {...props}>
            <SimpleForm>
                <TextField source="id" />
                <ReferenceField label="Process" source="processId" reference="process">
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory">
                    <TextField source="name" />
                </ReferenceField>

                <DateField source="createdAt" label="Creation date" />
                <DateField source="updatedAt" label="Update date" />
            </SimpleForm>
        </Show>
    );
};

const CreateProcessRawMaterialCategory = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput optionText="name" required={true} />
                </ReferenceInput>

                <ReferenceInput label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    );
};

const EditProcessRawMaterialCategory = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" required={true} />
                </ReferenceInput>

                <ReferenceInput label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" required={true} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};


const processMaterialsCategoryResource = () => <Resource
    name="processRawMaterialCategory"
    options={{ label: 'Process raw material category', menuGroup: 'Bcome' }}
    list={ProcessRawMaterialCategoryList}
    create={CreateProcessRawMaterialCategory}
    edit={EditProcessRawMaterialCategory}
    show={ShowProcessRawMaterialCategory} />;

export default processMaterialsCategoryResource;