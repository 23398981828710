import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  PasswordInput,
  SelectArrayInput,
  Filter,
  DateField,
  Resource,
  Datagrid } from "react-admin";
import roles from "../moqup/Roles";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const UsersList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="type" />
          <TextField source="roles" />
          <TextField source="credentials" />
          <TextField source="email" />
          <TextField source="id" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const CreateUsers = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email" />
        <TextInput source="name" />
        <PasswordInput source="credentials" label="Password" />
        <SelectArrayInput source="roles" choices={roles} />
      </SimpleForm>
    </Create>
  );
};

const EditUsers = (props) => {

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="email" />
        <TextInput source="name" />
        <SelectArrayInput source="roles" choices={roles} />
      </SimpleForm>
    </Edit>
  );
};

const usersResource = () => <Resource
  name="users"
  options={{ label: 'Users', menuGroup: 'Accounts'}}
  list={UsersList}
  create={CreateUsers}
  edit={EditUsers} />;


// const usersResource = (props) =>
//   BasicResource({
//     ...props,
//     name: "users",
//     list: (props) => UsersList(props),
//     create: (props) => CreateUsers(props),
//     edit: (props) => EditUsers(props),
//   });

export default usersResource;
