import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    TabbedShowLayout,
    useRecordContext,
    DateField,
    Resource,
    Show,
    ReferenceInput,
    ReferenceField,
    Filter,
    Tab,
    Datagrid,
    ArrayInput,
    AutocompleteInput,
    SimpleFormIterator,
    TabbedForm,
    FormTab,
} from "react-admin";



const Filters = (props) => (
    <Filter {...props}>

        <ReferenceInput alwaysOn label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput alwaysOn label="Material category" source="materialCategoryId" reference="materialsCategory" filterToQuery={searchText => ({ name: searchText })} >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </Filter>
);

const RawMaterialMaterialProcessesList = (props) => {
    return (
        <List {...props} filters={Filters(props)}>
            <div style={{ marginTop: '15px' }}>
                <Datagrid rowClick="show">
                    <TextField source="id" />

                    <ReferenceField label="Raw material category" source="rawMaterialCategoryId" reference="rawMaterialCategory">
                        <TextField source="name" />
                    </ReferenceField>

                    <ReferenceField label="Material category" source="materialCategoryId" reference="materialsCategory">
                        <TextField source="name" />
                    </ReferenceField>

                    <TextField source="lossRateRawMaterial" />
                    <TextField source="lossRateManufacture" />

                    <DateField source="createdAt" label="Creation date" />
                    <DateField source="updatedAt" label="Update date" />
                </Datagrid>
            </div>
        </List>
    );
};

const ProcessesList = (props) => {
    const record = useRecordContext();
    if (record) {
        if (record.processes.length > 0) {
            return (
                <List {...props} actions={null} bulkActionButtons={false} resource="process" filter={{ id: record.processes.map(x => x.id) }}>
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="id" />
                    </Datagrid>
                </List>);
        } else {
            return <span>There are no processes</span>
        }
    }
    return null;
}

const ShowRawMaterialMaterialProcesses = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id" />
                    <ReferenceField label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory">
                        <TextField source="name" />
                    </ReferenceField>

                    <ReferenceField label="Material category" source="materialCategoryId" reference="materialsCategory">
                        <TextField source="name" />
                    </ReferenceField>

                    <TextField source="lossRateRawMaterial" />
                    <TextField source="lossRateManufacture" />

                    <DateField source="createdAt" label="Creation date" />
                    <DateField source="updatedAt" label="Update date" />
                </Tab>

                <Tab label="processes">
                    <ProcessesList {...props} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const CreateRawMaterialMaterialProcesses = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput source="name" />
                </ReferenceInput>

                <ReferenceInput label="Material category" source="materialCategoryId" reference="materialsCategory" filterToQuery={searchText => ({ name: searchText })}>
                    <AutocompleteInput optionText="name" required={true} />
                </ReferenceInput>


                <TextInput source="lossRateRawMaterial" required={true} />
                <TextInput source="lossRateManufacture" required={true} />

            </SimpleForm>
        </Create>
    );
};


const EditRawMaterialMaterialProcesses = (props) => {

    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="summary">
                    <ReferenceInput label="Raw material Category" source="rawMaterialCategoryId" reference="rawMaterialCategory" filterToQuery={searchText => ({ name: searchText })}>
                        <AutocompleteInput source="name" required={true} />
                    </ReferenceInput>

                    <ReferenceInput label="Material category" source="materialCategoryId" reference="materialsCategory" filterToQuery={searchText => ({ name: searchText })}>
                        <AutocompleteInput source="name" required={true} />
                    </ReferenceInput>
                    <TextInput source="lossRateRawMaterial" required={true} />
                    <TextInput source="lossRateManufacture" required={true} />
                </FormTab>
                <FormTab label="processes">
                    <ArrayInput source="processes">
                        <SimpleFormIterator disableReordering getItemLabel={(index) => `${index + 1}`}>
                            <ReferenceInput label="process name" source="id" reference="process" filterToQuery={searchText => ({ name: searchText })}>
                                <AutocompleteInput source="name" />
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};


const rawMaterialMaterialProcessesResource = () => <Resource
    name="rawMaterialMaterialProcesses"
    options={{ label: 'Raw material Material processes', menuGroup: 'Bcome' }}
    list={RawMaterialMaterialProcessesList}
    create={CreateRawMaterialMaterialProcesses}
    edit={EditRawMaterialMaterialProcesses}
    show={ShowRawMaterialMaterialProcesses} />;

export default rawMaterialMaterialProcessesResource;