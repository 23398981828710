import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  Filter,
  AutocompleteInput,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="material.name" alwaysOn />
    <ReferenceInput label="Material" source="materialId" reference="material">
      <SelectInput optionText="materialName" />
    </ReferenceInput>
    <ReferenceInput label="Process" source="processId" reference="process">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
      <SelectInput optionText="supplierName" />
    </ReferenceInput>
    <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Transport" source="transportId" reference="transport">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const SubReference = ({ translateChoice, children, ...props }) => {
  const { record } = props;
  return <ReferenceField {...props} source="categoryId" reference="lcaCategory" filter={{ id: record.categoryId }}>{children}</ReferenceField>
}

const MaterialProcessList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{ marginTop: '15px' }}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Material" source="materialId" reference="material">
            <TextField source="materialName" />
          </ReferenceField>
          <ReferenceField label="Process" source="processId" reference="process">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Category" source="processId" reference="process">
            <SubReference source="proces.categoryId" reference="lcaCategory">
                <TextField source="name" />
            </SubReference>
          </ReferenceField>
          <ReferenceField label="Supplier" source="supplierId" reference="supplier">
            <TextField source="supplierName" />
          </ReferenceField>
          <ReferenceField label="Origin" source="originId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Origin matched" source="matchedOriginId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Origin process matched" source="matchedProcessOriginId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Transport" source="transportId" reference="transport">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="relativeAmount" />
          <TextField source="amount" />
          <TextField source="order" />
          <TextField source="automatic" />
          <TextField source="water" label="Water" />
          <TextField source="climateChange" label="Global warming" />
          <TextField source="eutrophication" label="Eutrophication" />
          <TextField source="fossilFuels" label="Fossil fuels" />
          <ReferenceField label="Account" source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowMaterialProcess = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <ReferenceField label="Material" source="materialId" reference="material">
          <TextField source="materialName" />
        </ReferenceField>
        <ReferenceField label="Process" source="processId" reference="process">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Category" source="processId" reference="process">
          <SubReference source="proces.categoryId" reference="lcaCategory">
              <TextField source="name" />
          </SubReference>
        </ReferenceField>
        <ReferenceField label="Supplier" source="supplierId" reference="supplier">
          <TextField source="supplierName" />
        </ReferenceField>
        <ReferenceField label="Origin" source="originId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Origin matched" source="matchedOriginId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Origin process matched" source="matchedProcessOriginId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Transport" source="transportId" reference="transport">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="relativeAmount" />
        <TextField source="amount" />
        <TextField source="order" />
        <TextField source="automatic" />
        <TextField source="water" label="Water" />
        <TextField source="climateChange" label="Global warming" />
        <TextField source="eutrophication" label="Eutrophication" />
        <TextField source="fossilFuels" label="Fossil fuels" />
        <ReferenceField label="Account" source="accountId" reference="accounts">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
        <EditButton />
      </SimpleForm>
    </Show>
  );
};

const CreateMaterialProcess = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>

        <ReferenceInput label="Material" source="materialId" reference="material">
          <SelectInput optionText="materialName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Process" source="processId" reference="process">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
          <SelectInput optionText="supplierName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="relativeAmount" />
        <TextInput source="amount" />
        <TextInput source="order" />
      </SimpleForm>
    </Create>
  );
};

const EditMaterialProcess = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput label="Material" source="materialId" reference="material">
          <SelectInput optionText="materialName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Process" source="processId" reference="process">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Supplier" source="supplierId" reference="supplier">
          <SelectInput optionText="supplierName" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <TextInput source="relativeAmount" />
        <TextInput source="amount" />
        <TextInput source="order" />
      </SimpleForm>
    </Edit>
  );
};



const materialProcessResource = () => <Resource
  name="materialProcess"
  options={{ label: 'Material processess', menuGroup: 'Materials' }}
  list={MaterialProcessList}
  create={CreateMaterialProcess}
  edit={EditMaterialProcess}
  show={ShowMaterialProcess} />;

export default materialProcessResource;
