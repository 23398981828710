import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Filter,
  Show,
  ImageField,
  SelectInput, BooleanField,BooleanInput,
Datagrid } from "react-admin";
import { categoryChoices, distanceChoices } from "../consts";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput source="category" choices={categoryChoices} />
    <SelectInput source="distance" choices={distanceChoices} />
    <TextInput label="Freight" source="freight" />
    <TextInput label="Type" source="type" />
  </Filter>
);

const TransportList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="category" />
          <TextField source="distance" />
          <TextField source="freight" />
          <TextField source="fuel_type" />
          <TextField source="type" />
          <TextField source="water" />
          <TextField source="climateChange" label="Global warming" />
          <TextField source="eutrophication" />
          <TextField source="fossilFuels" label="Fossil Fuels" />
          <BooleanField source="visibleInAppEcoCalculator" />
          <BooleanField source="visibleInWebEcoCalculator" />
          <ImageField source="icon" title="Icon" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowTransport = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="id" />
        <TextField source="category" />
        <TextField source="distance" />
        <TextField source="freight" />
        <TextField source="fuel_type" />
        <TextField source="type" />
        <TextField source="water" />
        <TextField source="climateChange" label="Global warming" />
        <TextField source="eutrophication" />
        <TextField source="fossilFuels" label="Fossil Fuels" />
        <BooleanField source="visibleInAppEcoCalculator" />
        <BooleanField source="visibleInWebEcoCalculator" />
        <ImageField source="icon" title="Icon" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateTransport = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="category" />
        <TextInput source="distance" />
        <TextInput source="freight" />
        <TextInput source="fuel_type" />
        <TextInput source="type" />
        <TextInput source="water" />
        <TextInput source="climateChange" label="Global warming" />
        <TextInput source="eutrophication" />
        <TextInput source="fossilFuels" label="Fossil Fuels" />
        <BooleanInput source="visibleInAppEcoCalculator" />
        <BooleanInput source="visibleInWebEcoCalculator" />
        <TextInput source="icon" label="Icon" />
      </SimpleForm>
    </Create>
  );
};

const EditTransport = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="category" />
        <TextInput source="distance" />
        <TextInput source="freight" />
        <TextInput source="fuel_type" />
        <TextInput source="type" />
        <TextInput source="water" />
        <TextInput source="climateChange" label="Global warming" />
        <TextInput source="eutrophication" />
        <TextInput source="fossilFuels" label="Fossil Fuels" />
        <BooleanInput source="visibleInAppEcoCalculator" />
        <BooleanInput source="visibleInWebEcoCalculator" />
        <TextInput source="icon" label="Icon" />
      </SimpleForm>
    </Edit>
  );
};


const transportResource = () => <Resource
  name="transport"
  options={{ label: 'Transports', menuGroup: 'Bcome'}}
  list={TransportList}
  create={CreateTransport}
  edit={EditTransport}
  show={ShowTransport} />;

export default transportResource;
