import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceField,
  SelectInput,
  Filter,
  ReferenceInput,
  AutocompleteInput, BooleanField,
  FunctionField,
  SelectArrayInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  Datagrid } from "react-admin";


import {EmptyValues} from "../components/EmptyValues"
import { handleRender, removeDuplicates } from "../utils/utils";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <ReferenceInput label="Project" source="projectId" reference="projects">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Category" source="categoryId" reference="lcaCategory">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
  </Filter>
);

const SupplierList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="supplierName" />
          <TextField source="id" />
          <TextField source="status" />
          <FunctionField
            className="supplier-tier"
            label="Tier"
            source="tier"
            render={
              record =>
              record.category.length > 0
              ? removeDuplicates(record.category, "tier")
              : (<EmptyValues />)}
          />
          <ReferenceField label="Project" source="projectId" reference="projects">
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Category"
            source="categoryId"
            render={
              record =>
              record.category.length > 0
              ? handleRender(record.category)
              : (<EmptyValues />)}
          />
          <ReferenceField label="Origin" source="originId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Transport" source="transportId" reference="transport">
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="useRenewableEnergy" label="Use of renewable energy"/>
          <BooleanField source="closedLoopWaterSystem" label="Closed Loop Water System"/>
          <BooleanField source="artisanalManufacture" label="Artisanal Manufacturer"/>
          <BooleanField source="fairtradeTrader" label="Fairtrade Trader"/>
          <BooleanField source="labourInsertionPrograms" label="Labour insertion programs"/>
          <TextField source="climateChange" />
          <TextField source="eutrophication" />
          <TextField source="fossilFuels" />
          <TextField source="comments" />
          <TextField source="total_certificate" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowSupplier = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="supplierName" />
        <TextField source="id" />
        <TextField source="status" />
        <FunctionField
          className="supplier-tier"
          label="Tier"
          source="tier"
          render={
            record =>
            record.category.length > 0
            ? removeDuplicates(record.category, "tier")
            : (<EmptyValues />)}
        />
        <ReferenceField label="Project" source="projectId" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
            label="Category"
            source="categoryId"
            render={
              record =>
              record.category.length > 0
              ? handleRender(record.category)
              : (<EmptyValues />)}
        />
        <ReferenceField label="Origin" source="originId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Transport" source="transportId" reference="transport">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="useRenewableEnergy" label="Use of renewable energy"/>
        <BooleanField source="closedLoopWaterSystem" label="Closed Loop Water System"/>
        <BooleanField source="artisanalManufacture" label="Artisanal Manufacturer"/>
        <BooleanField source="fairtradeTrader" label="Fairtrade Trader"/>
        <BooleanField source="labourInsertionPrograms" label="Labour insertion programs"/>
        <TextField label="Water" source="water" />
        <TextField label="Global warming" source="climateChange" />
        <TextField label="Eutrophiction" source="eutrophication" />
        <TextField source="fossilFuels" />
        <TextField source="comments" />
        <TextField source="total_certificate" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CustomBestPractice = ({value, ...rest}) => {
  const { label } = rest

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 10, alignItems: "center"}}>
      <TextInput source={value} disabled {...{label}} defaultValue={value} />
      <BooleanInput {...rest} label="Declared"  />
    </div>
  )
}

const CreateSupplier = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="supplierName" />
        <TextInput source="status" />
        <ReferenceInput label="Category" source="categoryId" reference="lcaCategory">
          <SelectArrayInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>

        <CustomBestPractice value="Use of renewable energy" source="useRenewableEnergy" label="Best practices"/>
        <CustomBestPractice value="Closed Loop Water System" source="closedLoopWaterSystem" label="Best practices"/>
        <CustomBestPractice value="Artisanal Manufacturer" source="artisanalManufacture" label="Best practices"/>
        <CustomBestPractice value="Fairtrade Trader" source="fairtradeTrader" label="Best practices"/>
        <CustomBestPractice value="Labour insertion programs" source="labourInsertionPrograms" label="Best practices"/>

        <TextInput label="Water" source="water" />
        <TextInput label="Global warming" source="climateChange" />
        <TextInput label="Eutrophiction" source="eutrophication" />
        <TextInput source="fossilFuels" />
        <TextInput source="comments" label="Comments" />
        <NumberInput source="total_certificate" label="Total Certificate" />
      </SimpleForm>
    </Create>
  );
};


const EditSupplier = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="supplierName" />
        <TextInput source="status" />
        <ReferenceArrayInput label="Category" source="categoryId" reference="lcaCategory">
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput optionText="name" required={true} />
        </ReferenceInput>

        <CustomBestPractice value="Use of renewable energy" source="useRenewableEnergy" label="Best practices"/>
        <CustomBestPractice value="Closed Loop Water System" source="closedLoopWaterSystem" label="Best practices"/>
        <CustomBestPractice value="Artisanal Manufacturer" source="artisanalManufacture" label="Best practices"/>
        <CustomBestPractice value="Fairtrade Trader" source="fairtradeTrader" label="Best practices"/>
        <CustomBestPractice value="Labour insertion programs" source="labourInsertionPrograms" label="Best practices"/>

        <TextInput label="Water" source="water" />
        <TextInput label="Global warming" source="climateChange" />
        <TextInput label="Eutrophiction" source="eutrophication" />
        <TextInput source="fossilFuels" />
        <TextInput source="comments" label="Comments" />
        <NumberInput source="total_certificate" label="Total Certificate" />
      </SimpleForm>
    </Edit>
  );
};


const supplierResource = () => <Resource
  name="supplier"
  options={{ label: 'Suppliers', menuGroup: 'Suppliers'}}
  list={SupplierList}
  create={CreateSupplier}
  edit={EditSupplier}
  show={ShowSupplier} />;

export default supplierResource;
