import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceField,
  SelectInput,
  Filter,
  ReferenceInput,
Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <ReferenceInput label="Project" source="projectId" reference="projects">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Type" source="typeId" reference="lcaGroup">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Category" source="categoryId" reference="materialsCategory">
        <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const MaterialList = (props) => {
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="materialName" label="Name" />
          <TextField source="id" />
          <ReferenceField label="Project" source="projectId" reference="projects">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Category" source="categoryId" reference="materialsCategory">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Application" source="applicationId" reference="materialsApplication">
            <TextField source="name" />
          </ReferenceField>

          <TextField label="LR applied" source="lossRateApplied" />
          <TextField label="LR manuf. applied" source="lossRateManufactureApplied" />

          <TextField source="water" label="Water" />
          <TextField source="climateChange" label="Global warming" />
          <TextField source="eutrophication" label="Eutrophication" />
          <TextField source="fossilFuels" label="Fossil fuels" />

          <TextField label="W by WP" source="waterByWetProcesses" />
          <TextField label="CC by WP" source="climateChangeByWetProcesses" />
          <TextField label="E by WP" source="eutrophicationByWetProcesses" />
          <TextField label="FF by WP" source="fossilFuelsByWetProcesses" />

          <TextField label="W by RME" source="waterByRawMaterialExtraction" />
          <TextField label="CC by RME" source="eutrophicationByRawMaterialExtraction" />
          <TextField label="E by RME" source="fossilFuelsByRawMaterialExtraction" />
          <TextField label="FF by RME" source="climateChangeByRawMaterialExtraction" />

          <TextField label="W by MP" source="waterByMaterialProcess" />
          <TextField label="CC by MP" source="eutrophicationByMaterialProcess" />
          <TextField label="E by MP" source="fossilFuelsByMaterialProcess" />
          <TextField label="FF by MP" source="climateChangeByMaterialProcess" />

          <TextField label="W by Coloration" source="waterByColoration" />
          <TextField label="CC by Coloration" source="eutrophicationByColoration" />
          <TextField label="E by Coloration" source="fossilFuelsByColoration" />
          <TextField label="FF by Coloration" source="climateChangeByColoration" />

          <TextField label="W by Treatment" source="waterByTreatment" />
          <TextField label="CC by Treatment" source="eutrophicationByTreatment" />
          <TextField label="E by Treatment" source="fossilFuelsByTreatment" />
          <TextField label="FF by Treatment" source="climateChangeByTreatment" />
          <TextField source="material_reference" label="Material reference" />

          <TextField source="comments"  label="Comments"/>


          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowMaterial = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="name" />
        <ReferenceField label="Project" source="projectId" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Type" source="typeId" reference="lcaGroup">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Category" source="categoryId" reference="materialsCategory">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Application" source="applicationId" reference="materialsApplication">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="LR applied" source="lossRateApplied" />
        <TextField label="LR manuf. applied" source="lossRateManufactureApplied" />

        <TextField source="water" label="Water" />
        <TextField source="climateChange" label="Global warming" />
        <TextField source="eutrophication" label="Eutrophication" />
        <TextField source="fossilFuels" label="Fossil fuels" />

        <TextField label="W by WP" source="waterByWetProcesses" />
        <TextField label="CC by WP" source="climateChangeByWetProcesses" />
        <TextField label="E by WP" source="eutrophicationByWetProcesses" />
        <TextField label="FF by WP" source="fossilFuelsByWetProcesses" />

        <TextField label="W by RME" source="waterByRawMaterialExtraction" />
        <TextField label="CC by RME" source="eutrophicationByRawMaterialExtraction" />
        <TextField label="E by RME" source="fossilFuelsByRawMaterialExtraction" />
        <TextField label="FF by RME" source="climateChangeByRawMaterialExtraction" />

        <TextField label="W by MP" source="waterByMaterialProcess" />
        <TextField label="CC by MP" source="eutrophicationByMaterialProcess" />
        <TextField label="E by MP" source="fossilFuelsByMaterialProcess" />
        <TextField label="FF by MP" source="climateChangeByMaterialProcess" />

        <TextField label="W by Coloration" source="waterByColoration" />
        <TextField label="CC by Coloration" source="eutrophicationByColoration" />
        <TextField label="E by Coloration" source="fossilFuelsByColoration" />
        <TextField label="FF by Coloration" source="climateChangeByColoration" />

        <TextField label="W by Treatment" source="waterByTreatment" />
        <TextField label="CC by Treatment" source="eutrophicationByTreatment" />
        <TextField label="E by Treatment" source="fossilFuelsByTreatment" />
        <TextField label="FF by Treatment" source="climateChangeByTreatment" />

        <TextField label="Material reference" source="material_reference" />

        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateMaterial = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Type" source="typeId" reference="lcaGroup">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="categoryId" reference="materialsCategory">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Application" source="applicationId" reference="materialsApplication">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput label="W BC" source="waterByMaterialComposition" />
        <TextInput label="CC BC" source="climateChangeByMaterialComposition" />
        <TextInput label="E BC" source="eutrophicationByMaterialComposition" />
        <TextInput label="FF BC" source="fossilFuelsByMaterialComposition" />
        <TextInput label="W BP" source="waterByProcess" />
        <TextInput label="CC BP" source="climateChangeByProcess" />
        <TextInput label="E BP" source="eutrophicationByProcess" />
        <TextInput label="FF BP" source="fossilFuelsByProcess" />
        <TextInput label="Material reference" source="material_reference" />
      </SimpleForm>
    </Create>
  );
};

const EditMaterial = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput label="Type" source="typeId" reference="lcaGroup">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="categoryId" reference="materialsCategory">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Application" source="applicationId" reference="materialsApplication">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput label="W BC" source="waterByMaterialComposition" />
        <TextInput label="CC BC" source="climateChangeByMaterialComposition" />
        <TextInput label="E BC" source="eutrophicationByMaterialComposition" />
        <TextInput label="FF BC" source="fossilFuelsByMaterialComposition" />
        <TextInput label="W BP" source="waterByProcess" />
        <TextInput label="CC BP" source="climateChangeByProcess" />
        <TextInput label="E BP" source="eutrophicationByProcess" />
        <TextInput label="FF BP" source="fossilFuelsByProcess" />
        <TextInput label="Material reference" source="material_reference" />
      </SimpleForm>
    </Edit>
  );
};


const materialResource = () => <Resource
  name="material"
  options={{ label: 'Materials', menuGroup: 'Materials'}}
  list={MaterialList}
  create={CreateMaterial}
  edit={EditMaterial}
  show={ShowMaterial} />;

export default materialResource;
